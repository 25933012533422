.tile {
    background-color: rgba(255, 255, 255, 0.8);
    border: 2px solid #676767;
    box-sizing: border-box;
    transition: all 0.15s;
    cursor: pointer;
    user-select: none;
    font-weight: normal;
}

.tile.color1 {
    background-color: #333333;
}

.tile.color2 {
    background-color: #82abbb;
}

.tile.color3 {
    background-color: #023047;
}

.tile.color4 {
    background-color: #eea041;
}

.tile.color5 {
    background-color: #6300af;
}

.tile.blocked {
    color: #fd8004;
    text-shadow: none;
    font-size: 9vmin;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.tile.blocked span {
    margin-top: -2vmin;
}

.tile.highlighted {
    border: 2px solid #44d3ff;
}
