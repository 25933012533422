@import-normalize; /* bring in normalize.css styles */

:root {
    --main-bg-color: #ffffff;
    --row-highlight-color: #44d3ff;
}

body {
    margin: 0;
    font-family: Tahoma, Verdana, Segoe, sans-serif;
}
#game {
    padding: 10px;
    color: #eee;
    cursor: default;
    user-select: none;
}

#game.unsolved {
    background: radial-gradient(circle at 50% 50%, #ffb64a, #af6d17);
}

#game.solved {
    background: radial-gradient(circle at 50% 50%, #4affd2, #0c5577);
}

#game-container {
    display: grid;
    place-items: center;
    min-height: 94vh;
    font-weight: bold;
}

#game-board {
    border-radius: 20px;
    background: #ffffff;
    padding: 20px;
}

.board-corner {
    width: 100%;
    display: inline-block;
}

.board-row {
    display: flex;
    justify-content: right;
}

.horz-row-container {
    display: flex;
    justify-content: right;
}

.horz-row {
    border-radius: 10px 10px 0 0;
    display: inline-block;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    flex-grow: 1;
    /* background-color: var(--main-bg-color); */
}

.horz-row:nth-child(even),
.board-row:nth-child(even) .vert-row {
    background-color: #f2f2f2;
}

.horz-row.highlighted {
    background: var(--row-highlight-color);
}

.horz-row span {
    display: block;
    height: 6vmin;
    width: 8vmin;
    text-align: center;
}

.vert-row {
    display: flex;
    align-items: center;
    border-radius: 10px 0 0 10px;
}

.board-row .vert-row.highlighted {
    background: var(--row-highlight-color);
}

.vert-row span {
    width: 5vmin;
    text-align: center;
}

.horz-row span,
.vert-row span {
    border-radius: 10px;
}

.horz-row .color1,
.vert-row .color1 {
    color: #333333;
}

.horz-row .color2,
.vert-row .color2 {
    background-color: #82abbb;
}

.horz-row .color3,
.vert-row .color3 {
    background-color: #023047;
}

.horz-row .color4,
.vert-row .color4 {
    background-color: #eea041;
}

.horz-row .color5,
.vert-row .color5 {
    background-color: #6300af;
}

/* Bad attempts to resize the board at various sizes */

.x10 .horz-row {
    width: 8vmin;
    font-size: 5vmin;
}

.x10 .vert-row {
    height: 8vmin;
    font-size: 5vmin;
    padding-right: 2vmin;
}

.x10 .tile {
    height: 8vmin;
    width: 8vmin;
}

.x20 .board-corner,
.x20 .horz-row,
.x20 .vert-row,
.x20 .tile {
    height: 2vh;
    width: 2vh;
    font-size: 1rem;
}
